import React, { useCallback, useEffect, useState } from "react";
import DeviceIcon from "@screencloud/screencloud-ui-components/.dist/components/device-icon/index";
import firebase from "firebase/compat/app";
import { StudioGraphQLClient } from "@screencloud/studio-graphql-client";
import { Dialog, Transition } from '@headlessui/react';
import ScreenDetail from "../ScreenDetail";

const channelByIdQuery = `
    query channelById($id: UUID!) {
      channelById(id: $id){
        id
        name
        coverData
        layoutByChannel {
          zones
          config
        }
      }
    }
  `

const playlistByIdQuery = `
  query playlistById($id: UUID!) {
    playlistById(id: $id){
      id
      name
    }
  }
`

const fileByIdQuery = `
  query fileById($id: UUID!) {
    fileById(id: $id){
      id
      name
    }
  }
`

const linkByIdQuery = `
  query linkById($id: UUID!) {
    linkById(id: $id){
      id
      name
    }
  }
`

const appInstanceByIdQuery = `
  query appInstanceById($id: UUID!) {
    appInstanceById(id: $id){
      id
      name
    }
  }
`

const refreshScreensMutation = `
  mutation refreshScreen($input:RefreshScreenInput!) {
    refreshScreen(input:$input) {
      screen {
        id
        name
      }
    }
  }
`

const clearCacheScreensMutation = `
  mutation clearCacheScreen($input:ClearCacheScreenInput!) {
    clearCacheScreen(input:$input) {
      screen {
        id
        name
      }
    }
  }
`

interface DeviceCardProps {
  screen: any
  endpoint?: string
  apiKey: string
  pageId?: string
  playerUrl?: string
}

const DeviceCard = (props: DeviceCardProps) => {
  const { screen, pageId, endpoint, apiKey, playerUrl } = props;
  const [content, setContent] = useState({} as any);
  const [screenDetailOpen, setScreenDetailOpen] = useState(false)
  const [openRefreshScreen, setOpenRefreshScreen] = useState(false)
  const [openClearCacheScreen, setOpenClearCacheScreen] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [shelvesData, setShelvesData] = useState({} as any);
  
  const gridName = screen?.name.match(/\[(.*?)\]/g) ? screen?.name.match(/\[(.*?)\]/g)[0].replace(/\[|\]/g, '') : ''
  const notesRef = firebase.firestore().collection("/menus").doc(pageId)  
  const ipAddressValue = shelvesData ? shelvesData.ip_address : ''
  const noteValue = shelvesData ? shelvesData.note : ''

  useEffect(() => {
    const unsubscribe = notesRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataChange = () => {
    notesRef.get().then((doc) => {
      if (doc.exists && doc.data()?.shelves[gridName]) {
        setShelvesData(doc.data()?.shelves[gridName])
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  const client = new StudioGraphQLClient({ 
    endpoint: endpoint,
    token: apiKey,
  });

  useEffect(() => {
    getContentData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContentData = useCallback(() => {

    const contentType = screen?.content._ref.type

    let query = ``
    const variables = { id: screen?.content._ref.id ?? ''}

    switch (contentType) {
      case "channel":
        query = channelByIdQuery
        break;
      case "playlist":
        query = playlistByIdQuery
        break
      case "file":
        query = fileByIdQuery
        break
      case "link":
        query = linkByIdQuery
        break
      case "appInstance":
        query = appInstanceByIdQuery
        break
      default:
        break;
    }

    client.request(query, variables).then((result) => {
      setContent(result[contentType + "ById"]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openScreenDetail() {
    setScreenDetailOpen(true)
  }

  function closeModal() {
    setScreenDetailOpen(false)
  }

  function handleRefreshScreen() {
    setIsActionLoading(true)
    const refreshScreenInput = {
      input: {
        screenId: screen?.id,
        device: screen?.device,
      }
    }

    client.request(refreshScreensMutation, refreshScreenInput).then((result) => {
      setIsActionLoading(false)
    });
  }

  function handleClearCacheScreen() {
    setIsActionLoading(true)
    const clearCacheScreenInput = {
      input: {
        screenId: screen?.id,
        device: screen?.device,
      }
    }

    client.request(clearCacheScreensMutation, clearCacheScreenInput).then((result) => {
      if (result?.clearCacheScreen?.screen?.id) {
        setIsActionLoading(false)
      }
    });
  }

  const renderScreenDetail = (screen:any, content?: any) => {
    return (
      <Transition appear show={screenDetailOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-2/3 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white"
                style={{ maxWidth: '960px' }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  <></>
                </Dialog.Title>
                <div>
                  <ScreenDetail
                    screen={screen}
                    content={content}
                    pageId={pageId}
                    apiKey={apiKey}
                    endpoint={endpoint}
                    playerUrl={playerUrl}
                    />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  const renderRefreshConfirmation = () => {
    return (
      <Transition appear show={openRefreshScreen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpenRefreshScreen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-2/3 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white"
                style={{ maxWidth: '460px', minHeight: '100px' }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  <></>
                </Dialog.Title>
                <div>
                  <h1 className="text-2xl mb-8">Refresh a selected screen?</h1>
                  <button className="rounded bg-slate-600 hover:bg-slate-900 text-white p-2 pl-4 pr-4 text-lg mr-2" onClick={() => {setOpenRefreshScreen(false); handleRefreshScreen()}}>OK</button> <button className="" onClick={() => setOpenRefreshScreen(false)}>Cancel</button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  const renderClearCacheConfirmation = () => {
    return (
      <Transition appear show={openClearCacheScreen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpenClearCacheScreen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-2/3 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white"
                style={{ maxWidth: '460px', minHeight: '100px' }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  <></>
                </Dialog.Title>
                <div>
                  <h1 className="text-2xl mb-8">Clear all caches on a selected screen?</h1>
                  <button className="rounded bg-slate-600 hover:bg-slate-900 text-white p-2 pl-4 pr-4 text-lg mr-2" onClick={() => {setOpenClearCacheScreen(false); handleClearCacheScreen()}}>OK</button> <button className="" onClick={() => setOpenClearCacheScreen(false)}>Cancel</button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  const renderDeviceStatus = () => (
    <div className={`text-green-500 rounded-full dark:text-green-100 dark:bg-green-500 inline-block mr-1`}>
    {screen?.device.connection === "online" ? (
      <svg
        className="w-5 h-5 "
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="m13 17v9h14v-9zm11 11c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1h-3c-1.1 0-2-.9-2-2v-9c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v9c0 1.1-.9 2-2 2z"
          transform="translate(-11 -13)"
        />
      </svg>
    ) : (
      <svg
        className="w-5 h-5 fill-red-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="m13 17v9h14v-9zm11 11c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1h-3c-1.1 0-2-.9-2-2v-9c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v9c0 1.1-.9 2-2 2z"
          transform="translate(-11 -13)"
        />
      </svg>
    )}
    </div>
  )

  const offlineStyle = {
    color: screen?.device.connection === "online" ? '#1f2027' : '#9D9D9D',
  }

  return (
    <>
    {renderScreenDetail(screen, content)}
    {renderRefreshConfirmation()}
    {renderClearCacheConfirmation()}
    <div className="w-full flex flex-col text-left h-full cursor-pointer device-card" onClick={() => openScreenDetail()} >
      <div className="">
        <div className="absolute top-3 right-3 text-xs flex align-center card-actions">
          {screen?.device.devicePlatform === "android" && <button onClick={(e) => {e.stopPropagation(); setOpenClearCacheScreen(true)}} className="font-semibold text-xs text-link ml-1" title="Clear Cache">&#10005; <span className="hidden text-3xl-visible">Clear Cache</span></button>}
          <button onClick={(e) => {e.stopPropagation(); setOpenRefreshScreen(true)}} className="font-semibold text-xs text-link ml-1" title="Refresh"><span className={`text-icon ${isActionLoading ? 'spinner' : ''}`}>&#8635;</span> <span className={`hidden text-3xl-visible`}>Refresh</span></button>
          <a
            href={`https://app.datadoghq.com/account/login?next=%2Flogs%3Fquery%3D%2540screenId%3A${screen.id}%26cols%3Dhost%252Cservice%252C%2540name%252C%2540contentType%252C%2540screenId%252C%2540orgId%26index%3D%252A%26messageDisplay%3Dinline%26stream_sort%3Ddesc%26from_ts%3D1645154979974%26to_ts%3D1645158579974%26live%3Dfalse`}
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-xs text-link ml-1"
            title="Datadog"
            onClick={(e) => {e.stopPropagation();}}
          >
            &#8599; <span className="hidden text-3xl-visible">Datadog</span>
          </a>
        </div>
      </div>

      <p className="text-xs justify-start flex">{renderDeviceStatus()} <span className="uppercase text-device-platform" title={screen?.device?.devicePlatform}>{screen?.device?.devicePlatform}</span><span className="info-device-model ml-1 opacity-50 truncate inline-block w-2/6">{screen?.device?.deviceModel}</span></p>

      <div className="flex flex-row pb-2 mb-4 border-b-1 border-slate-700">
        <div className="device-icon mr-4">
          <DeviceIcon model={screen?.device.deviceModel} platform={screen?.device.devicePlatform} />
        </div>
        <div style={offlineStyle}>
          <button className="flex items-start text-lg text-left text-name font-bold">
            <span style={offlineStyle}>{screen?.name}</span>
          </button>
          <p className="text-now-playing" style={{marginTop: '-0.5rem'}}><span className="capitalize bg-slate-100 p-1 text-xs rounded">{screen?.content._ref.type}</span> {content?.name}</p>
        </div>
      </div>
      
      <div className="flex flex-row w-full text-info">
        <div className=" w-full rounded-lg">
          <p className="text-sm ">
            <span className="font-bold">{screen?.playerRelease}</span> IP: <span className="font-bold">{ipAddressValue}</span> 
          </p>

          {noteValue && <p className="text-sm text-note">
            Note: <span className="text-rose-600">{noteValue}</span> 
          </p>}
          
        </div>
      </div>

    </div>
    </>
  )
}

export default DeviceCard;